
import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
@Component
export default class Resize extends Vue {
  @Ref("searchBoxRef") searchBoxRef: HTMLElement;

  tableMaxheight = 0;
  tempSurplusHeight = 100;

  windowResize(h: number  = 100) {
    this.tempSurplusHeight = h;
    this.setSuperHeight();
    this.$nextTick(() => {
        this.setTableMaxheight();
    })
    window.onresize = () => {
      this.setSuperHeight();
      this.setTableMaxheight()
    }
  }

  setSuperHeight() {
    const domRect = this.searchBoxRef?.getBoundingClientRect();
    this.tempSurplusHeight = domRect ? domRect.height : this.tempSurplusHeight;
  }

  setTableMaxheight() {
    this.tableMaxheight = window.innerHeight - this.tempSurplusHeight - (this.searchBoxRef ? 250 : 50);
  }
}